import * as React from "react";
import { graphql } from "gatsby";
import { Box, Container } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import SliceZone from "../components/SliceZone/SliceZone";

import MooseHockeyIceMask from "../components/IceMasks/MooseHockeyIceMask";
import MooseHockey from "../components/HomePage/HomeContent/MooseHockey"

import StripeDivder from "../components/IceMasks/StripeDivider";
import GameSchedule from "../components/MooseHockeyPage/GameSchedule"
import BackgroundImage from '../components/Backgrounds/BackgroundImage'
import DonateNewsletterBlock from "../components/DonateNewsletterBlock/DonateNewsletterBlock";
import Footer from "../components/Footer/Footer"
import TeamStats from "../components/MooseHockeyPage/TeamStats";
import TeamRoster from "../components/MooseHockeyPage/TeamRoster";

const MooseHockeyPage = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicPage.edges[0].node.data;
  const HeroImage = document.hero_image?.localFile.publicURL;
  const secondaryImage = document.secondary_hero_image.localFile;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;

  console.log(document)

  return (
    <>
    <Seo title="Snow King Sports &amp; Events Center | Moose Hockey" />
      <MooseHockeyIceMask hockeyImage={HeroImage} />
      <StripeDivder />

      <Box backgroundColor="white">
        <Container maxW="container.xl">
          
          <GameSchedule />
          
          <MooseHockey imageDisplay="none" />

          <TeamStats />
        </Container>
        <StripeDivder />

        <TeamRoster />

        <Box my="20">
            <SliceZone sliceZone={document.body} />
          </Box>
   
        <BackgroundImage
          backgroundImage={getImage(backgroundImage)}>
        <StripeDivder />
        
      
        <DonateNewsletterBlock 
          title="Book Your Private Event On The Ice"
          content="Now, of course not, Biff, now, I wouldn't want that to happen. Well, now we gotta sneak this back into my laboratory, we've gotta get you home. Yeah. I can't play. Just say anything, George, say what ever's natural, the first thing that comes to your mind."
          btnText="Book Today To Reserve Your Spot"
          btnLink="/contact"
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
    allPrismicPage(filter: {uid: {eq: "moose-hockey"}}) {
      edges {
        node {
          uid
          data {
            hero_image {
              localFile {
                absolutePath
                publicURL
              }
              alt
            }
            secondary_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            page_title {
              text
            }
            page_subtitle {
              text
            }
            info_oval_button_1_label
            info_oval_button_1_link {
              url
              target
            }
            info_oval_button_2_label
            info_oval_button_2_link {
              url
              target
            }
            info_oval_title
            info_oval_content {
              richText
            }
            body {
              ... on PrismicPageDataBodyPageContent {
                slice_type
                primary {
                  page_content {
                    richText
                  }
                }
              }
              ... on PrismicPageDataBodyFeaturedImage {
                id
                slice_type
                primary {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodyBannerAdsCarousel {
                id
                slice_type
                primary {
                  banner_ads {
                    document {
                      ... on PrismicBannerAds {
                        id
                        data {
                          banner_ad {
                            mobile_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            ad_link {
                              url
                            }
                            desktop_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodySponsors {
                id
                slice_type
                items {
                  sponsor_name
                  sponsor_link {
                    url
                  }
                  sponsor_logo {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MooseHockeyPage;
